<template>
  <div class="row">
    <div class="col-sm-8" style="text-align: left;">
      <p><span>Merging accounts combines the logins, contact methods, and enrollments for two accounts.</span></p>

      <p><span>If the account being merged is enrolled in a course that the account it is being merged into is not enrolled in, grade information will be transferred to the merged account. If both accounts are enrolled in the same course, grade information from the account being merged will not be transferred.</span>
      </p>

      <p><span>After the accounts have been merged, the user can use the logins that originated from either account to view all their course enrollments within the same account.</span>
      </p>
    </div>
    <div class="col-sm-3">
      <div class="row">
        <div style="text-align: left;">
          <label>Search</label>
          <input class="form-control" v-model="searchText" type="text" spellcheck="false" placeholder="Name, Email, Organization"/>
        </div>
        <div style="text-align: right;">
          <button type="button" class="button btn-primary bi-search" @click="searchUsers">Search</button>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="row">
      <h3 style="font-weight: bold;">Merging</h3>
      <div class="col-sm " style="text-align: left;">
        <h6>Name: {{ userBeingMerged.firstName }} {{ userBeingMerged.lastName }}</h6>
        <h6>Email: {{ userBeingMerged.email }}</h6>
        <h6>Position: {{ userBeingMerged.positionName }}</h6>
      </div>
      <div class="col-sm" style="text-align: left;">
        <h6>ESU: {{ userBeingMerged.esuName !== null ? userBeingMerged.esuName : "No ESU" }}</h6>
        <h6>District: {{ userBeingMerged.districtName !== null ? userBeingMerged.districtName : "No District" }}</h6>
        <h6>School: {{ userBeingMerged.schoolName !== null ? userBeingMerged.schoolName : "No School" }}</h6>
        <h6>Outstate Organization: {{
            userBeingMerged.inNebraska ? "Not Outstate" : userBeingMerged.outStateOrgName
          }}</h6>
      </div>
    </div>
  </div>
  <div class="container-lg mt-2" v-if="showResults">
    <table id="userTable" class="table table-bordered table-striped">
      <thead>
      <tr>
        <th v-for="(value, name, index) in fieldHeaders" :key="index" @click="sortTable(name)">{{ value }}
          <i v-if="name === 'position'"
             :class="name === prevSearchCol && storedReverse ? 'bi bi-sort-up' : 'bi bi-sort-down'"
             aria-label='Sort Icon'>
            <b-tooltip target="tooltip-target-role" triggers="hover" title="User's Privilege/Access Level"
                       style="font-style: normal;" class="bi bi-info-circle">
            </b-tooltip>
          </i>
          <i v-else-if="name === 'accountStatus'"
             :class="name === prevSearchCol && storedReverse ? 'bi bi-sort-up' : 'bi bi-sort-down'"
             aria-label='Sort Icon'
             id="tooltip-target-status">
            <b-tooltip target="tooltip-target-status" triggers="hover" class="bi bi-info-circle"
                       title="User's account is inactive or active"
                       style="font-style: normal;">
            </b-tooltip>
          </i>
          <i v-else :class="name === prevSearchCol && storedReverse ? 'bi bi-sort-up' : 'bi bi-sort-down'"
             aria-label='Sort Icon'></i></th>

      </tr>
      </thead>
      <tbody>
      <tr v-for="item in updatedList" :key="item">
        <td v-for="(value, name, index) in fieldHeaders" :key="index">

          <p v-if="name === 'email'" style="word-break: break-all;"> {{ item[name] }}</p>
          <p v-else-if="name === 'accountStatus'">{{ item[name] === true ? 'Enabled' : 'Disabled' }}</p>
          <p v-else-if="name === 'canvasId'">{{ item[name] !== -1 ? item[name] : 'No Canvas Account' }}</p>
          <router-link v-else-if="name === 'merge'"
                       :to="{name: 'MergeUser', params: {idKeepingUser: item['idUser'], idRemovingUser: userBeingMergedId}}">
            Merge into
          </router-link>
          <p v-else>{{ item[name] }}</p>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

</template>

<script>
import {computed, onBeforeMount, ref} from 'vue';
import axios from "axios";
import {useStore} from "vuex";
import {searchForUser} from "@/Helpers/ApiCalls/UserAPICalls";
import {sortBy} from "lodash/collection";
import {API_URL} from "../../../Constants";

export default {
  name: "UserMergeSearchTable",
  props: {
    userBeingMergedId: String
  },
  setup(props) {
    const searchText = ref("");
    const users = ref([]);
    const store = useStore();
    const authToken = store.getters['auth/authenticated'];
    const sort = ref(false);
    const updatedList = ref(false);
    const prevSearchCol = ref("");
    const storedReverse = ref(false);
    const userBeingMerged = ref({});

    onBeforeMount(() => {
      getUserBeingMerged();
    })

    const fieldHeaders = ref({
      name: "Name",
      email: "Email",
      esu: "ESU",
      district: "District",
      school: "School",
      educationalOrganization: "Organization",
      state: "State",
      country: "Country",
      registrationDate: "Registration Date",
      position: "Role",
      canvasId: "Canvas ID",
      merge: "Merge",
    })

    async function searchUsers() {
      await axios.get(searchForUser(), {
        params: {
          keyword: searchText.value
        },
        headers: {
          "Authorization": "Bearer " + authToken.value
        }
      }).then((result) => {
        users.value = setUserObjects(result.data);
      }).finally(() => {
        updatedList.value = users.value;
      })
    }

    async function getUserBeingMerged() {
      let get_uri = API_URL + "/user/byId?_idUser=" + parseInt(atob(props.userBeingMergedId));
      await axios.get(get_uri, {
        headers: {
          "Authorization": "Bearer " + authToken.value
        }
      }).then((result) => {
        userBeingMerged.value = result.data;
      })
    }

    const showResults = computed(() => {
      return users.value.length > 0;
    })

    function setUserObjects(userList) {
      let userObjList = [];
      for (let i = 0; i < userList.length; i++) {
        // Exclude the user who will be merged
        if (userList[i]['idUser'] !== parseInt(atob(props.userBeingMergedId))) {
          let user = {
            name: userList[i]['firstName'] + " " + userList[i]['lastName'],
            email: userList[i]['email'],
            state: userList[i]['stateName'],
            country: userList[i]['countryName'],
            esu: userList[i]['esuName'],
            district: userList[i]['districtName'],
            accountStatus: userList[i]['accountEnabled'] !== null ? userList[i]['accountEnabled'] : false,
            registrationDate: userList[i]['registrationDate'],
            idUser: btoa(userList[i]['idUser']),
            position: userList[i]['positionName'],
            educationalOrganization: (userList[i]['outStateOrgName'] !== null && userList[i]['outStateOrgName'] !== undefined)
                ? userList[i]['outStateOrgName'] : "",
            canvasId: userList[i]['canvasId'] !== null ? userList[i]['canvasId'] : -1,
            merge: `Merge ${userList[i]['firstName']} ${userList[i]['lastName']}`
          }
          userObjList.push(user);
        }
      }
      return userObjList;
    }

    const sortTable = (col) => {
      sort.value = true;
      if (col !== prevSearchCol.value) {
        storedReverse.value = false;
        if (col !== "accountStatus") {
          updatedList.value = sortBy(users.value, col);
        } else {
          updatedList.value = sortBy(users.value, col);
        }
      } else {
        storedReverse.value = !storedReverse.value;
        updatedList.value = updatedList.value.reverse();
      }
      prevSearchCol.value = col;
    }

    return {
      users,
      showResults,
      searchText,
      userBeingMerged,
      prevSearchCol,
      storedReverse,
      fieldHeaders,
      updatedList,
      sortTable,
      searchUsers,
    }
  }
}
</script>

<style scoped>

</style>