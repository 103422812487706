import {API_URL} from "../../../Constants";

export const deleteUser = (idUser) => {
    return `${API_URL}/user/delete/${idUser}`;
}

export const mergeUser = (idKeepingUser, idRemovingUser) => {
    return `${API_URL}/user/merge/keeping/${idKeepingUser}/removing/${idRemovingUser}`;
}

export const changeEnrollmentByUserId = (idUser) => {
    return `${API_URL}/user/changeEnrollment/${idUser}`
}

export const searchForUser = () => {
    return `${API_URL}/user/search`
}

export const searchUserCount = () => {
    return `${API_URL}/user/search/count`;
}

export const registrationExport = () => {
    return `${API_URL}/user/registrationExport`;
}